<template>
    <v-container class="pa-0 ma-0" fluid>
        <v-row class="pa-0 ma-0">
            <v-col class="pb-0 mb-0" cols="8">
                <v-autocomplete :items="filteredItems" :loading="loadingSearch"
                    :search-input.sync="search" item-text="sizevariation" item-value="key" return-object @change="updateItems"
                    :label="$t('erp.lang_SizeVariation')" clearable outlined flat hide-details/>
            </v-col>
            <v-col class="text-right" align-self="center" cols="4">
                <slot name="add" />
            </v-col>
            <v-col cols="12">
                <v-data-table :headers="dataTableHeader" :items="selectedItems" :show-select="false">
                    <template v-slot:item.totalprice="{ item }">
                        <v-text-field v-model.number="item.totalprice" type="number" hide-details dense outlined/>
                    </template>
                    <template v-slot:item.crud="{ index }">
                        <v-btn text fab color="error" @click="removeItem(index)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import validation from "../../../mixins/validation/validation";
export default {
    name: "ItemMealSizes",
    props: {
        value: {
            type: Array,
            default: ()=> []
        }
    },
    mixins:[validation],
    data() {
        return {
            loading: false,
            search: null,
            selectedItems: [],
            items: [],
            awaitingSearch: null,
            loadingSearch: false
        }
    },
    computed:{
        filteredItems(){
            return this.items.filter(item=>{
                return !this.selectedItems?.some(selectedItem => selectedItem?.key==item?.key)
            }).map(item=>({
                key: item?.key,
                sizevariation: item?.sizevariation,
                totalprice: 0,
            }))
        },
        dataTableHeader(){
            return [
                {
                    text: "ID",
                    value: "key",
                    sortable: false,
                    hide: true
                },
                {
                    text: this.$t("erp.lang_SizeVariation"),
                    value: "sizevariation",
                    sortable: false,
                },
                {
                    text: this.$t("erp.lang_newTotalPrice"),
                    width: 300,
                    value: "totalprice",
                    sortable: false,
                },
                {
                    text: '',
                    value: "crud",
                    align: "center",
                    sortable: false,
                },
            ]
        }
    },
    watch:{
        selectedItems(v){
            this.$emit('input', v);
        },
        search(val) {
            clearTimeout(this.awaitingSearch);

            this.awaitingSearch = setTimeout(() => {
                if ((val && val.length === 0) || !val) {
                    this.items = [];
                    return;
                }

                this.loadingSearch = true;

                this.axios
                    .post(ENDPOINTS.DATATABLES.ERP.SIZEVARIATION, {
                        sEcho: 1,
                        iDisplayStart: 0,
                        iDisplayLength: 15,
                        iSortCol_0: 1,
                        bSortable_1: true,
                        sSortDir_0: 'asc',
                        iSortingCols: 1,
                        sSearch: val,
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            this.items = res.data.aaData?.map(item => {
                                return {
                                    key: item[0],
                                    sizevariation: item[1],
                                    totalprice: item[3],
                                }
                            }) || [];
                        }
                    })
                    .catch(() => { })
                    .finally(() => {
                        this.loadingSearch = false;
                    });
            }, 500);
        },
    },
    methods: {
        updateItems(entry){
            if(!entry)
                return;
            this.selectedItems.push(entry);
        },
        removeItem(index){
            this.selectedItems.splice(index, 1)
        }
    },
    mounted(){
        if (this.value && this.value?.length > 0) {
            this.selectedItems = [...this.value]
        }
    }
}
</script>